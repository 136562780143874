import React, {useState, useEffect} from 'react';
import {Content, Section, Item, Image,ItemTitle, ItemDescription, ItemLink} from "./Styledcs"
import api from '../components/axios'



// const baseURL=baseURL
const endpoint="events/"
 

export default function Events() {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);



  const [ events, setEvents ] = useState([]);

    useEffect(()=> {
        api.get(endpoint).then(res=>{
            // console.log(res.data)
            // Result Array of Python Dictionary items[{…}, {…}, {…}]
            setEvents(res.data) 
            
        })
    }, 
    []); 

 
  return (
     <div id="events">
      <h1  data-aos='fade-right'
            data-aos-duration='1000'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'>EVENTS AT EMBASSY</h1>
      
     <Section>
      <Content style={{margin: "20px 0"}}>
      {events.map((event, index) => (
       <Item key={index} 
      data-aos='zoom-out-up'
            data-aos-duration='1200'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'>

<Image src={event.events_thumbnail} alt={event.events_name} />
<ItemTitle>{event.events_name}</ItemTitle>
<ItemDescription>{event.events_short_description.substring(0,80)}
 </ItemDescription>
 <ItemLink to={`/events/${event.events_name}`}>
            {event.events_button}
    </ItemLink>


       
    </Item>))}
 
    </Content>
    </Section></div>
  );
}
