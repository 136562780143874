import React, { useState, useEffect } from 'react';
import {FaBars} from 'react-icons/fa'
import {animateScroll as scroll } from "react-scroll";
import {Nav,NavLogo,NavbarContainer,HashLink, MobileIcon, NavMenu ,NavBtn,NavBtnLink, NavItem} from '../Navbar/NavbarElements';
import logo from '../Navbar/logo.png';
import api from '../axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
           

const NavbarBar2 = ({toggle}) => {
    const [loading,setLoading]=useState(true)
    const endpoint="navigation/"
    // Trying to push to new link
    // const path = useLocation()
    // const thislink=path.pathname
    // console.log(thislink)
    // // console.log(linkRule)
    const [navbar, setNavbar]=useState(false);
    const [navbarData, setNavbarData]=useState([])
    const changeBackground=()=>{
        if(window.scrollY>=80){
            setNavbar(true);
        }else{
            setNavbar(false);
        }
    };
    useEffect(()=>{
        api.get(endpoint).then(res=>{
            setNavbarData(res.data)
            setLoading(false)
        })
    },[])

 
    window.addEventListener("scroll",changeBackground);

    

    return (
        <Nav style={{backgroundColor:navbar? '#000':'#000',}}>
            <NavbarContainer>
                <NavLogo to ="/" onClick={() => {
    scroll.scrollToTop();
}}>
    <img src={logo} alt="Logo" width={130} />
    </NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars/>
                </MobileIcon>



                {loading?
                ( <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open>
        <CircularProgress color="inherit" />
      </Backdrop>)
      :(<NavMenu>
         {navbarData.map((item)=>( 
        
       
      <NavItem key={item.id}>
      <HashLink to={"/#"+item.link}>{item.title}</HashLink>
      </NavItem>
   
      ))}
 
  
                    
                </NavMenu>)}

                <NavBtn>
                    
                <NavBtnLink to={"/#contactus"}
                                  onClick={() => {
                                    scroll.scrollToTop();}} >Contact Us</NavBtnLink>
            
            
                </NavBtn>
            </NavbarContainer>
            
        </Nav>
    )
}

export default NavbarBar2

