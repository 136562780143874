import React, { useEffect, useState } from 'react'
import { SidebarContainer, Icon,NavLinkr, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap,SidebarRoute } from './SidebarElements'
import {animateScroll as scroll } from "react-scroll";

import api from '../axios';



 
        
const Sidebar = ({toggle, isOpen}) => {
    const endpoint="navigation/"

    const [navbarData,setNavbarData]=useState([])
    useEffect(()=>{
        api.get(endpoint).then(res=>{
            setNavbarData(res.data)
        })
    },[])
    return (
        <SidebarContainer isOpen={isOpen} toggle={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    {navbarData.map((item,index)=>(
                        
                       <SidebarLink to={item.link} key={index} onClick={toggle}>{item.title}</SidebarLink>
                        // <NavLinkr to={item.link} key={index}  
                        // // onClick={toggle}
                        //  onClick={() => { 
                        //     scroll.scrollToTop();
                        //     toggle();
                        // }}>{item.title}</NavLinkr>
                    
                    ))}
                </SidebarMenu>
<SideBtnWrap>
                <SidebarRoute to="contactus" onClick={toggle}> 
Contact Us
                </SidebarRoute> 
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
            
        
    )
}

export default Sidebar
