import React, {  useEffect, useState } from 'react';
import Hero from '../components/Hero';
import api from '../components/axios';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Logo from "../components/Navbar/logo.png"

 

function SlideShow() {
  const [open, setOpen] =useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


    const endpoint="slider/";
    const [slides, setSlides]=useState([]);
    const [loading,setLoading]=useState(true)
 

  useEffect(() => {
    api.get(endpoint).then(res=>{
      setSlides(res.data)
      setLoading(false)

    })  
  }, []);

  return (
     <div style={{height:"100vh",
    backgroundColor:"black"}}>
      {loading?( <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
        
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      
      ):(
      
     

<Hero slides={slides}/>
      
      )}
    </div>
  )
}

export default SlideShow;
