import React, { useState,useEffect } from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap,SidebarRoute } from './SidebarElements'
import api from "../axios"

const Sidebar2 = ({toggle, isOpen}) => {
    const endpoint="navigation/"
    const[navbarData, setNavbarData]=useState([])
    useEffect(()=>{
        api.get(endpoint).then(res=>{
            setNavbarData(res.data)
        })
    })
    return (
        <SidebarContainer isOpen={isOpen} toggle={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    {navbarData.map((item,index)=>(
                    <SidebarLink  key={index}  to={"/"}
                             style={{color:'#fff',
                                display:'flex',
                                alignItems:'center',
                                textDecoration:'none',
                                padding:'0 1rem',
                                height:'100%',
                                transition:'0.2s ease-in-out',
                                "&:hover": {
                                    color:'#ffff00',
                                  }}} 
                                onClick={toggle}>{item.title} 
                                
                    </SidebarLink>
                     
                    ))}
                </SidebarMenu>
<SideBtnWrap>
                <SidebarRoute to="contactus" onClick={toggle}> 
Contact Us
                </SidebarRoute> 
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
            
        
    )
}

export default Sidebar2
