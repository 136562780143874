import React, { useEffect, useState } from 'react';
import {FaBars} from 'react-icons/fa'
import {animateScroll as scroll } from "react-scroll";
import logo from '../Navbar/logo.png';
import {Nav,NavLogo,NavbarContainer, MobileIcon, NavMenu, NavItem,NavLinks, NavBtn,NavBtnLink,} from './NavbarElements.js';
import api from '../axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

 
const Navbar = ({toggle}) => {
    const [loading,setLoading]=useState(true)
    const endpoint="navigation/"
    const [navbar, setNavbar]=useState(false);
    const [navbarData, setNavbarData]=useState([])
    const changeBackground=()=>{
        if(window.scrollY>=90){
            setNavbar(true);
        }else{
            setNavbar(false);
        }
    };

    useEffect(()=>{
        api.get(endpoint).then(res=>{
            setNavbarData(res.data)
            setLoading(false)
        })
    },[])

    window.addEventListener("scroll",changeBackground);

    return (
        <Nav style={{backgroundColor:navbar? '#000':'',}}>
            <NavbarContainer>
                <NavLogo to ="/" onClick={() => {
    scroll.scrollToTop();
}}>
    <img src={logo} alt="Logo" width={130} />
    </NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars/>
                </MobileIcon>

{loading?
( <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
        
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      
      )
      :(
                <NavMenu>
                    
                        {navbarData.map((item, index)=>( 
                            
                            <NavItem key={index}>
                             <NavLinks 
                             style={{color:'#ebe0bd'}}
                             activeClass="active"
                             spy={true}
                             smooth={true}
                             offset={-70}
                             duration={500}
                             to={item.link} >{item.title}</NavLinks>
                             </NavItem>
                        ))}
                    
                </NavMenu>)}

                <NavBtn>
                    
                <NavBtnLink to ='contactus' onClick={() => scroll.scrollToBottom()}>Contact Us</NavBtnLink>  
            
            
                </NavBtn>
            </NavbarContainer>
            
        </Nav>
    )
}

export default Navbar
