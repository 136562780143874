import React, {  useEffect} from 'react';
import Gallery from '../views/Gallery';
import Aboutus from '../views/Aboutus';
import AppFooter from '../views/AppFooter';
import SlideShow from '../views/SlideShow';
import Events from '../views/Events';
import Services from '../views/Services';
import ContactUs from '../views/ContactUs';
import AppBar from '../components/AppBar';
import withRoot from '../withRoot';

// One Way
// import Hero from '../components/Hero';
// import {SliderData} from'../data/aSliderData';
import Aos from 'aos';
import 'aos/dist/aos.css';

const  Index= ()=> {

  // const [slides, setSlides]=useState([]);

  // const getAllSlides = async () => {
  //   const info = await SliderData();
  //   setSlides(info);
  // }


  useEffect(() => {
    window.scrollTo(0, 0)
    Aos.init({});
    // getAllSlides();
  }, []);

  

  
  return (
    <React.Fragment>
      <AppBar />
      <SlideShow/>
      <Events/>
      <Aboutus />
      <Services/>
      <Gallery/>
    <ContactUs/> 
      <AppFooter/>
    </React.Fragment>
  );
}

export default withRoot(Index);
