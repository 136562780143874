import * as React from 'react';
import {useState, useEffect} from 'react';
import api from '../components/axios';
import { Content, Item, ItemDescription, ItemLink, ItemTitle, Section, Image } from "./Styledcs"




// const baseURL=baseURL
const endpoint="services/"

 

 

export default function Services() {
  


  const [ services, setServices ] = useState([]);

    useEffect(()=> {
        api.get(endpoint).then(res=>{
            // console.log(res.data)
            // Result Array of Python Dictionary items[{…}, {…}, {…}]
            setServices(res.data) 
            
        })
    }, 
    []); 

 
  return (
     <div id="services">
       
      <h1  data-aos='fade-right'
            data-aos-duration='1000'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'>SERVICES AT EMBASSY</h1>
      
     <Section>
      <Content style={{margin: "20px 0"}}>
      
       

     
      {services.map((service, index) => (

<Item key={index} 
data-aos='zoom-out-up'
      data-aos-duration='1200'
      data-aos-once='true'
      data-aos-anchor-placement='center bottom'>

<Image src={service.services_image} alt={service.services_name} />
<ItemTitle>{service.services_name}</ItemTitle>
<ItemDescription>{service.services_short_description.substring(0,80)}
</ItemDescription>
<ItemLink
to={`/services/${service.services_name}`}>
{service.services_booking_button} 
</ItemLink>


 
</Item>))}
         
       

            

            
          
</Content>
    </Section></div>
  );
}
