import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Section=styled.div`
overflow:hidden;
background-color:#f7f7f9;
`

export const Content=styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: top;
    justify-content: center;
`
export const Item=styled.div` 
    margin:20px;
    max-width:300px;
    background-color:#ffffff;
    `
export const LargeImage=styled.img` 
    width: 100%;
    height:100%;
    object-fit: cover;
`


  export const Image=styled.img` 
    width: 100%;
    height:280px;
    object-fit: cover;
    
`

export const ItemTitle=styled.h3`
text-align: center;
  text-transform:uppercase;
  font-size: 110%;
  margin: 5px;
`


export const ItemDescription=styled.p`
 text-align: center;
  font-size: 90%;
  padding:0 20px;
`


export const ItemLink=styled(Link)`
    display: block;
    text-align: center;
    font-size: 80%;
    padding:5px 20px 20px 20px;
    color:#ae894d;
    text-transform: uppercase;
`

  /* #ABOUT US SECTION */
  
  export const Wrapper=styled.div` 
    display: flex;
    flex-direction: row;
    background-color: #fff;
    width:100% ;
    align-items: top;
    justify-content: center;

    @media screen and (max-width: 800px) {
    flex-direction: column;
      flex-direction: column-reverse;
      margin: 0;
      padding: 0;};
`

export const Leftsection=styled.div` 
 background-color: #fff;
    width: 50%;
    max-width: 800px;
    margin-top: 40px;
    padding: 5em;
    @media screen and (max-width: 800px) {
        width: 100%;
        align-items: flex-end;
        margin-top: 10px;
        padding: 3em;
       };
`

export const LeftsectionDestails=styled.p` 
text-align:justify;
  font-size: 100%;
  padding:0;
`

export const Rightsection=styled.div` 
    background-color: #fff;
    width: 50%;
    max-width: 800px;
    @media screen and (max-width: 800px) {
        width: 100%;
       };
`
 
 
  
    /* #Contact us page */
    export const Contactus=styled.div` 
     display: flex;
      flex-direction: row;
      background-color:#1b1b1b;

      @media screen and (max-width: 800px) {
        flex-direction: column;
      }
   `
  
  export const ContactusButton=styled.div`
      margin: 10px auto;
      background-color: #fbf3da;
      color:#000;
      display:flex;
      width:100%;
      padding:10px;
`