import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';
import Typography from '../components/Typography';
import {Link as LinkR } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import api from '../components/axios';
import CloseIcon from '@mui/icons-material/Close';


const Section = styled.section`
  /* background-color: #f7f7f9; */
  display: flex;
  flex-wrap:wrap;
  flex-direction: row;
  justify-content: center;
  margin:0em;
  padding:4rem;

  @media screen and (max-width: 768px) {
    padding:0rem ;
    margin:0px;
    display: flex;
    flex-wrap:wrap;
    flex-direction: column;
    justify-content: center;
    
  }
`;

 

const ColumnLeftside = styled.div`
  margin: 0px;
  padding: 0px;
  max-width: 850px;
  width: 50%;
  min-height: 60vh;
  background-color:"#000" ;

 
  @media screen and (max-width: 768px) {
    min-height: 420px;
    width: 100%;
    margin: 0px;
    padding:0px;
  }

`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  margin:4em;
  flex: 0 0 50%;
  color: #000;
  padding-bottom:4em;
  

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom:5em;
    
  }

  h1 {
    margin-bottom: 0rem;
    margin-top: 0rem;
    font-size: 3rem;
   
  }

  h2 {
    margin-bottom: 0rem;
    margin-top: 0rem;
    font-size: 2rem;
  }

  h4 {
    margin-bottom: 0.3rem;
    margin-top: 0.3rem;
    font-size: 1rem;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.5;
  }
`;

const ColumnRightside = styled.div`
  background-color: #fff;
  max-width: 800px;
  min-height: 60vh;
  width: 50%;
  padding: 0rem;
  margin:0 ;
  justify-content: center;


  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    left: 0px;
    justify-content: center;
    
  }

`;



export const FoodBtn =styled.nav`
display:inline-center;
align-items:center;
font-size:70%;
`;


export const FoodBtnLinkr=styled(LinkR)`
border-radius:15px;
background:#ebe0bd;
white-space:nowrap;
padding:10px 52px;
margin:10px;
color:#010606;
font-size:8px;
outline:none;
border:none;
cursor:pointer;
float:right;
transition:all 0.2s ease-in-out;
text-decoration:none;

&:hover{
    transition:all 0.2s ease-in-out;
    background:#fbe0bd;
    color:#000;
}
`;

const endpoint="services/"


const ServicesDetails = () => {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const params = useParams();
  const enquiredservice=params.serviceId
  // console.log(enquiredservice);

  const [ services, setServices ] = useState([]);
  useEffect(()=> {
        // call Data from api
        api.get(endpoint).then(res=>{
            setServices(res.data) 
        })
   

    }, 
    []); 

  const myIndex=services.findIndex(e => e.services_name === enquiredservice)
  // console.log(myIndex +"is my current index")

  const getLength=services.length-1

//Get Next service
const getNext=services.filter((e,i)=>i===(myIndex+1)).map(d=>d.services_name)[0]
//Get Previous service
const getPrevious=services.filter((e,i)=>i===(myIndex-1)).map(d=>d.services_name)[0]



  return (
    <div style={{backgroundColor: "#f7f7f9", marginTop:"10px"}} >
     

        {services.map((service, index)=>(
          <div key={index}> { myIndex === index && (

            
    <Section id="servicesdetail" >

      {myIndex !== 0 && (
         <Link to={`/services/${getPrevious}`}> 
      <ArrowBackIosIcon style={{position:" absolute",
        top: "60%",
        left:"20px", color:"#b09a77", fontSize:"300%" }}/>
        </Link>
      )}
     
    <p style={{clear:"both", width:"100%",textTransform:"capitalize"}}>Services / {service.services_name}</p><br/>

        <ColumnLeftside id="columnleft">
            <Image
              src={service.services_image}
              data-aos='fade-left'
              data-aos-duration='1200'
              data-aos-once='true'
              data-aos-anchor-placement='center bottom'
            />
          </ColumnLeftside>


          <ColumnRightside id="columnright">
          <Link to="/"> 
          <CloseIcon style={{position:" absolute", margin:"10px",
        right:"60px", color:"#b09a77", fontSize:"250%" }}></CloseIcon></Link>
          
            <Content style={{display:'block', padding:"0"}}
              data-aos='fade-right'
              data-aos-duration='1200'
              data-aos-delay='300'
              data-aos-once='true'
              data-aos-anchor-placement='center bottom'
            >
              <Typography style={{color:'#000', fontSize:"130%"}} variant="h4" marked="center" align="left" component="h5" data-aos='fade-right'
            data-aos-duration='1000'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'>
      {service.services_name}
      </Typography>
      
      <Typography style={{color:'#000', fontSize:"130%"}} align="left" component="h5" data-aos='fade-right'
            data-aos-duration='1000'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'>
      {service.services_time}
      </Typography>


       


              <p>{service.services_long_description}</p>
               
               
<div style={{display:"flex", frexWrap:"wrap", flexDirection:"column", alignItems:"center"}}>
                  <FoodBtn>
                    
                    <FoodBtnLinkr to ={"#"}><h4>{service.services_booking_number}</h4></FoodBtnLinkr>
                    {/* <FoodBtnLinkr to ={"#"}><h4>{service.services_booking_button}</h4></FoodBtnLinkr> */}
                   
                
                
                    </FoodBtn>
                    <h4>{service.services_booking_email}</h4>
                    <Typography style={{fontSize:"12px"}}>*Patrons must be 18+ and not prohibited from the Entertainment at our venue. Embassy practices responsible service of alcohol. Entry and VIP Booths are subject to availability and bookings are recommended.</Typography>
  </div>


            </Content>
          </ColumnRightside>
          {myIndex !== getLength && (
           
           <Link to={`/services/${getNext}`}>
         <ArrowForwardIosIcon  style={{position:" absolute",
        top: "60%",
        right:"10px", color:"#b09a77", fontSize:"300%" }}/>
        </Link>
        )}
         
    </Section>)}
    </div>
    
    ))}
    </div>
  );
};

export default ServicesDetails;
