import * as React from 'react';

import SocialMedia from'./SocialMedia'
import Copyright from'./Copyright'

export default function AppFooter() {
  return (
     <>
    <SocialMedia/>  
    <Copyright/> 
    </>
    
  );
}
