import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Typography from '../components/Typography';
import api from '../components/axios';


const Section = styled.section`
  background: #fff;
  display: flex;
  flex-wrap:wrap;
  flex-direction: row;
  justify-content: center;
  margin:0px;
  padding: 0rem;

  @media screen and (max-width: 768px) {
    padding:0rem ;
    margin:0px;
    display: flex;
    flex-wrap:wrap;
    flex-direction: column;
    justify-content: center;
    
  }
`;

 

const ColumnLeftside = styled.div`
  margin: 0px;
  padding: 0px;
  max-width: 850px;
  width: 50%;
  min-height: 320px;
  background-color:"#000" ;

 
  @media screen and (max-width: 768px) {
    min-height: 220px;
    width: 100%;
    margin: 0px;
    padding:0px;
  }

`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  margin:4em;
  flex: 0 0 50%;
  color: #000;
  padding-bottom:4em;
  

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom:5em;
    
  }

  h1 {
    margin-bottom: 0rem;
    margin-top: 0rem;
    font-size: 3rem;
   
  }

  h2 {
    margin-bottom: 0rem;
    margin-top: 0rem;
    font-size: 2rem;
  }

  h4 {
    margin-bottom: 0.3rem;
    margin-top: 0.3rem;
    font-size: 1rem;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.5;
  }
`;

const ColumnRightside = styled.div`
  
  max-width: 800px;
  height: 100%;
  width: 50%;
  padding: 0rem;
  margin:0 ;
  justify-content: center;


  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    left: 0px;
    justify-content: center;
    
  }

`;



export const FoodBtn =styled.nav`
display:inline-center;
align-items:center;
font-size:70%;
`;


export const FoodBtnLinkr=styled.a`
border-radius:15px;
background:#ebe0bd;
white-space:nowrap;
padding:10px 52px;
margin:10px;
color:#000;
font-size:8px;
outline:none;
border:none;
cursor:pointer;
float:right;
transition:all 0.2s ease-in-out;
text-decoration:none;

&a{
  color:#000;
}

&:hover{
    transition:all 0.2s ease-in-out;
    background:#fbe0bd;
    color:#000;
}

`;




const Gallery = () => {
  const endpoint="gallery/"
  const [gallery, setGallery]=useState([])
  useEffect(()=>{
    api.get(endpoint).then(res=>{
      setGallery(res.data);
    })
  },[])

  return (
    <div id="gallery">
      {gallery.map((item,index) => (
    <Section id="gallery" key={index}>
       
        <ColumnLeftside id="columnleft">
            <Image
              src={item.headimage}
              data-aos='fade-left'
              data-aos-duration='1200'
              data-aos-once='true'
              data-aos-anchor-placement='center bottom'
            />
          </ColumnLeftside>


          <ColumnRightside id="columnright">
          
            <Content style={{display:'block', padding:"0"}}
              data-aos='fade-right'
              data-aos-duration='1200'
              data-aos-delay='300'
              data-aos-once='true'
              data-aos-anchor-placement='center bottom'
            >
              <Typography style={{color:'#000', fontSize:"130%"}} variant="h4" marked="center" align="center" component="h5" data-aos='fade-right'
            data-aos-duration='1000'
            data-aos-once='true'
            data-aos-anchor-placement='center bottom'>
              {item.title}
       
      </Typography>
      <div dangerouslySetInnerHTML={{__html: item.description}}  ></div>
               
               
<div style={{display:"flex", frexWrap:"wrap", flexDirection:"column", alignItems:"center"}}>
                
                 
                
                 
             <FoodBtnLinkr  rel="noopener noreferrer" target="_blank" href={`${item.facebook}`}><h4>Facebook</h4>      
</FoodBtnLinkr>
<FoodBtnLinkr  rel="noopener noreferrer" target="_blank" href={`${item.instagram}`}><h4>Instagram</h4>      
</FoodBtnLinkr>
 
               
  </div>
            </Content>
          </ColumnRightside>
         
         
    </Section>))}</div>
  );
};

export default Gallery;
