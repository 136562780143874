import React, {  useEffect } from 'react';
import AppFooter from '../views/AppFooter'

import withRoot from '../withRoot';
import EventsDetails from '../views/EventsDetails';

import Aos from 'aos';
import 'aos/dist/aos.css';
import AppBar2 from '../components/AppBar2';

import { useLocation } from "react-router-dom";




function EventsPage() {
  const { pathname } = useLocation();


  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({});
  }, []);

  
  return (
    <React.Fragment>
      <AppBar2/>
      <EventsDetails />
      <AppFooter/>
    </React.Fragment>
  );
}

export default withRoot(EventsPage);
