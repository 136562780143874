import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '../components/Typography';
 
export default function Copyright() {
  return (
 
    <Box  
    component="section"   
    sx={{ display: 'flex', overflow: 'hidden', bgcolor: '#000',flexDirection: 'column', margin:'0', padding:'10px' }}
  >  
   

    <Typography  style={{color:"#ebe0bd",margin:'0', padding:'40px'}} align="center" component="h2" data-aos='fade-right'
          data-aos-duration='1000'
          data-aos-once='true'
          data-aos-anchor-placement='center bottom'>
    &copy; Embassy Club, Hong Kong, 2022
    </Typography>
     
  
  
  </Box>
  );
}
