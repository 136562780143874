import React, { Component, useState } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';



const MapContainer = (props) => {


const mapStyles = {
  maxWidth:'500px',
  height: '500px',
  position: 'relative',
  
};


const [showInfoWindow, setShowInfoWindow]=useState(false) // Hides or shows the InfoWindow
const [activeMarker, setActiveMarker]=useState({})
const [selectedPlace, setSelectedPlace]=useState({})// Shows the active marker upon click
 
const onMarkerClick=()=>{
  console.log('Clicked')
  setShowInfoWindow(true)
  setActiveMarker()

}
  
  return (




 
 
      <div style={{minWidth:'400px',
      height: '500px',
      position: 'relative',}}>




<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.987270093709!2d114.16842384999838!3d22.278472049245956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34040131ce927ec3%3A0xc23470f000672659!2sEmbassy!5e0!3m2!1sen!2shk!4v1678414983144!5m2!1sen!2shk" width="100%" height="100%" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
{/*  
      <Map
        className='googlemapposition'
        google={props.google}
        zoom={15}
        style={{mapStyles}}
        initialCenter={
          {
            lat: 22.278683,
            lng: 114.170631
          }
        }
      >
        <Marker onClick={onMarkerClick}
                name={'Embassy'} />
 
        <InfoWindow 
        visible={true}
        style={{width:'250px', height:'250px',backgroundColor:'#000'}}
        >
             
              <h1>Embassy</h1>
            
        </InfoWindow>
        </Map>
*/}
        </div>
    );
      }

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDdBh-PF5j9fUaYYoBzB_omOk3MuxO5ikI'
})(MapContainer);