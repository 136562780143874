import React, {useState} from 'react';
import './App.css'
import {
  // BrowserRouter,
  HashRouter as Router,
  Routes,
  Route
} from "react-router-dom";

 
import HomePage from "./pages/HomePage";
import EventsPage from "./pages/EventsPage";
import ServicesPage from './pages/ServicesPage';
import PageNotFound from './pages/PageNotFound';
 
 


function App() {
  


  return (
   
  <Router>
    <Routes>
      <Route path="/" exact element={<HomePage />} />
      <Route path="/events/:eventId" element={<EventsPage />} />
      <Route path="/services/:serviceId" element={<ServicesPage />} />
      <Route path="*" element={<PageNotFound />} />
      
      
    </Routes>
  </Router>
)};

export default App;
