import React from 'react';
import {createRoot} from 'react-dom/client';

// import { Provider } from 'react-redux'
// import store from './store'

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const container =document.getElementById('root')
const root =createRoot(container);
root.render(
  <App/>
);

// ReactDOM.render(
   
   
//     <App />
//   ,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




// import React from 'react';
// import ReactDOM from 'react-dom';
// // import { Provider } from 'react-redux'
// // import store from './store'

// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
   
   
//     <App />
//   ,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();