import React, {  useEffect } from 'react';
 
import AppFooter from '../views/AppFooter'
import AppBar2 from '../components/AppBar2';
import withRoot from '../withRoot';
import ServicesDetails from '../views/ServicesDetails';
 
import Aos from 'aos';
import 'aos/dist/aos.css';

import { useLocation } from "react-router-dom";


function ServicesPage() {
  const { pathname } = useLocation();


  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({});
  }, [pathname,]);

  
  return (
    <React.Fragment>
      <AppBar2/>
      <ServicesDetails/>
      <AppFooter/>
    </React.Fragment>
  );
}

export default withRoot(ServicesPage);
